import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { BillingService } from '@payments/services/billing.service'
import { NbDialogRef, NbDialogService } from '@nebular/theme'
import { TopUpModalComponent } from '@payments/components/top-up-modal/top-up-modal.component'
import { WorkspaceRoleService } from '@app/modules/auth/services/workspace-role.service'
import { FormControl, Validators } from '@angular/forms'
/**
 * Represents the customer wallet
 */
@Component({
  selector: 'ngx-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit {
  /**
   * Customer model
   */
  @Input()
  customer: any
  /**
   * Payment methods
   */
  @Input()
  paymentMethods: any

  /**
   * Whether a reload is requested
   */
  @Output()
  reload: EventEmitter<any> = new EventEmitter()
  /**
   * Available top up amount
   */
  amounts = [100, 200, 300, 500, 800, 1300, 2100, 3400]
  /**
   * Current selected amount
   */
  selectedAmount: number = 0
  /**
   * Custom amount entered by the user
   */
  customAmountControl = new FormControl(null, [Validators.min(5),Validators.pattern('^[0-9]*$')])
  /**
   * Selected payment method
   */
  selectedPaymentMethod: any = null
  /**
   * Performing an async operation?
   */
  processing = false
  /**
   * Check if payment gateway is dibsy
   */
  isDibsy: boolean

  /**
   * Creates the component and injects it's dependencies
   * @param service BillingService
   * @param dialogService NbDialogService
   * @param workspaceRole WorkspaceRoleService
   */
  constructor(private service: BillingService, private dialogService: NbDialogService, private workspaceRole: WorkspaceRoleService) {}

  ngOnInit(): void {
    this.isDibsy = this.service.workspace.parent_workspace
      ? !!this.service.workspace.parent_workspace.options?.payment?.gateway?.dibsy
      : !!this.service.workspace.options?.payment?.gateway?.dibsy


  }

  /**
   * Topup dialog modal ref
   */
  private _modalRef: NbDialogRef<TopUpModalComponent>
  /**
   * Sets the selected amount and clears the custom amount
   * @param amount number
   */
  selectAmount(amount) {
    this.selectedAmount = amount
    this.customAmountControl.setValue(null)
  }
  /**
   * Sets the custom amount and clears the selected amount
   * @param amount number
   */
  updateCustomAmount() {
    if (!this.customAmountControl.value) {
      this.selectedAmount = 0;
      return; 
    }

    let amount = this.customAmountControl.value
    this.customAmountControl.setValue(this.isDibsy ? this.verifyMinimumAmount(amount, 50) : this.verifyMinimumAmount(amount, 5))
    this.selectedAmount = 0
  }
  /**
   * Verify the passed amount is not less than the minimum amount
   * @param amount number
   * @param minimumAmount number
   * @returns number
   */
  verifyMinimumAmount(amount: number, minimumAmount: number) {
    if (amount < minimumAmount) amount = minimumAmount
    return amount
  }
  /**
   * Sets the selected payment method
   * @param paymentMethod any
   */
  selectPaymentMethod(paymentMethod) {
    this.selectedPaymentMethod = paymentMethod
  }
  /**
   * Top ups the specified amount with the selected payment method or prompt the user to enter a payment method
   * @param newPaymentMethod boolean
   * @returns void
   */
  topup(newPaymentMethod = false) {
    if (newPaymentMethod) {
      this.dialogService
        .open(TopUpModalComponent, {
          context: {
            amount: !!this.customAmountControl.value ? this.customAmountControl.value : this.selectedAmount
          }
        })
        .onClose.subscribe((result) => {
          if (!this.paymentMethods.data.length) {
            this.service.paymentMethods().subscribe((data: any) => {
              if (data.data.length) {
                this.service.setDefaultPaymentMethod(data.data[0].id).subscribe((pres) => {
                  this.service.reloadBillingDetails$.next(true)
                  this.processing = false
                })
              } else {
                this.service.reloadBillingDetails$.next(true)
                this.processing = false
              }
            })
          } else {
            this.service.reloadBillingDetails$.next(true)
            this.processing = false
          }
        })
    }

    if (!((this.selectedAmount !== 0 || (!!this.customAmountControl.value && this.customAmountControl.value > 0)) && !!this.selectedPaymentMethod)) {
      return
    }
    this.processing = true

    this.service.topup(!!this.selectedAmount ? this.selectedAmount : this.customAmountControl.value, this.selectedPaymentMethod.id).subscribe((result) => {
      if (!this.paymentMethods.data.length) {
        this.service.paymentMethods().subscribe((data: any) => {
          if (data.data.length) {
            this.service.setDefaultPaymentMethod(data.data[0].id).subscribe((pres) => {
              this.service.reloadBillingDetails$.next(true)
              this.processing = false
            })
          } else {
            this.service.reloadBillingDetails$.next(true)
            this.processing = false
          }
        })
      } else {
        this.service.reloadBillingDetails$.next(true)
        this.processing = false
      }
      this.selectedAmount = 0;
      this.customAmountControl.setValue(null);
      this.selectedPaymentMethod = null;
    })
  }

  /**
   * Checks if the user can add a new payment method
   * @returns boolean
   */
  canAddCreditcard(): boolean {
    return this.workspaceRole.canAddCreditcard()
  }

  allowOnlyNumbers(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }
}
