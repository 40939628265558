import { environment } from '@environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, BehaviorSubject, of } from 'rxjs'
import { Injectable } from '@angular/core'
import { tap, map, catchError } from 'rxjs/operators'
import { Workspace } from '@workspaces/interfaces/workspace.interface'
import { PlanPrice } from '@workspaces/models/plan-price.interface'
import { IRunningJob } from '@app/modules/shared/header/running-jobs/model/running-jobs.interface'
import { productFruits } from 'product-fruits'
import posthog from 'posthog-js'

/**
 * Defines an organization profile - legal info
 */
export interface LegalInfo {
  /** Company address */
  company_address?: string
  /** Company description */
  company_description?: string
  /** Company email */
  company_email: string
  /** Company name */
  company_name: string
  /** Company phone */
  company_phone?: string
  /** Company type */
  company_type: number
  /** Country */
  country: string
  /** Created at */
  created_at?: string
  /** Company metadata */
  data?: any
  /** Company logo */
  company_logo?: string
  /** Legal info id */
  id?: number
  /** Updated at */
  updated_at?: string
  /** Workspace id */
  workspace_id: any
  /** Industry_sector */
  industry_sector_id?: number
}

/**
 * Service that handles authentication, authorization and related functionality
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /**
   * Current application version
   */
  public _version = environment.appVersion
  /**
   * Emits the current user when it changes
   */
  public userChange: BehaviorSubject<any> = new BehaviorSubject(this.user)
  /**
   * Emits the current workspace when it changes
   */
  public currentWorkspaceChange: BehaviorSubject<Workspace> = new BehaviorSubject(this.currentWorkspace)
  /**
   * Emits the current parent workspace when it changes
   */
  public currentParentWorkspaceChange: BehaviorSubject<Workspace> = new BehaviorSubject(this.currentParentWorkspace)
  /**
   * Whether the terms of services are displayed
   */
  public displayTerms$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  /**
   * Whether the privacy policy is displayed
   */
  public displayPrivacy$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  /**
   * Whether the available plans changed
   */
  public plansChange: BehaviorSubject<any> = new BehaviorSubject(this.plans)
  /**
   * Whether the role of current user in current workspace has changed
   */
  public userRoleInCurrentWorksapce: BehaviorSubject<any> = new BehaviorSubject(null)

  /**
   * BehaviorSubject for storing running jobs.
   * @private
   */
  private runningJobsSource = new BehaviorSubject<IRunningJob[]>([])
  /**
   * Observable for broadcasting running job changes.
   * @public
   */
  runningJobs$ = this.runningJobsSource.asObservable()
  /**
   * gets the current user from the local storage
   */
  public get user() {
    const user = localStorage.getItem('user')
    return user ? JSON.parse(user) : null
  }

  /**
   * Sets the current user in the local storage and calls userChange
   * @param value User
   */
  public set user(value) {
    if (value === null) {
      localStorage.removeItem('user')
      posthog.reset()
    } else {
      localStorage.setItem('user', JSON.stringify(value))
    }
    posthog.identify(value['id'], {
      email: value['email'],
    })
    this.userChange.next(value)
  }

  /**
   * Sets whether the user just logged in
   */
  public set justLoggedIn(value) {
    localStorage.setItem('justLoggedIn', value)
  }

  /**
   * Gets whether the user just logged in
   */
  public get justLoggedIn() {
    const justLoggedIn = localStorage.getItem('justLoggedIn')
    return justLoggedIn
  }

  /**
   * Sets the plans available in local storage
   * @param value Plans
   */
  public set plans(value) {
    if (value === null) {
      localStorage.removeItem('plans')
    } else {
      localStorage.setItem('plans', JSON.stringify(value))
    }
    this.plansChange.next(value)
  }

  /**
   * Gets the plans available from local storage
   */
  public get plans() {
    const plans = localStorage.getItem('plans')
    return plans ? JSON.parse(plans) : []
  }

  /**
   * Gets the current available workspaces from local storage
   */
  public get workspaces() {
    const workspaces = localStorage.getItem('workspaces')
    return workspaces ? JSON.parse(workspaces) : []
  }

  /**
   * Sets the current available workspaces in local storage
   */
  public set workspaces(value: any[]) {
    if (value === null) {
      localStorage.removeItem('workspaces')
    } else {
      localStorage.setItem('workspaces', JSON.stringify(value))
    }
  }

  /**
   * Sets the current workspace in local storage and emits currentWorkspaceChange
   */
  public set currentWorkspace(value: Workspace) {
    if (value === null) {
      localStorage.removeItem('current-workspace')
    } else {
      posthog.register({
        workspace: value.name,
        workspace_id: value.id,
        workspace_slug: value.slug,
      })
      localStorage.setItem('current-workspace', JSON.stringify(value))
    }
    this.currentWorkspaceChange.next(value)
  }

  /**
   * Determines whether the user can edit workspace organization info
   */
  public get canEditOrganization(): boolean {
    return !!this.currentParentWorkspace && this.currentParentWorkspace.is_auth_owner && this.currentParentWorkspace.role_id === 1
  }

  /**
   * Gets the current workspace from local storage
   */
  public get currentWorkspace(): Workspace {
    const workspace = localStorage.getItem('current-workspace')
    return workspace ? JSON.parse(workspace) : null
  }

  /**
   * Sets the current parent workspace in local storage and emits currentParentWorkspaceChange
   */
  public set currentParentWorkspace(value: Workspace) {
    if (value === null) {
      localStorage.removeItem('current-parent-workspace')
    } else {
      localStorage.setItem('current-parent-workspace', JSON.stringify(value))
    }
    this.currentParentWorkspaceChange.next(value)
  }

  /**
   * Gets the current parent workspace from local storage
   */
  public get currentParentWorkspace(): Workspace {
    const workspace = localStorage.getItem('current-parent-workspace')
    return workspace ? JSON.parse(workspace) : null
  }

  /**
   * Creates the service & injects it's dependencies
   * @param http HttpClient
   * @param router Router
   */
  constructor(private http: HttpClient) {}

  /**
   * Gets the current application version
   * @returns Observable
   */
  getLiveVersion(autoGenerated = Math.random().toString().replace('0.', '')): Observable<any> {
    return this.http.get(`${environment.baseUrl}version?version=${this._version}&current=${autoGenerated}`).pipe(tap((data) => (this._version = data['version'])))
  }

  /**
   * Login using email and password
   * @param formValue Value of email and password
   * @returns Observable
   */
  public login(formValue: any): Observable<any> {
    return this.http.post(`${environment.planningApi}auth/login`, formValue)
  }

  /**
   * Login using a provided token
   * @param token user token
   * @returns Observable
   */
  public loginWithToken(token: string): Observable<any> {
    return this.http.get(`${environment.planningApi}auth`, {
      headers: new HttpHeaders({
        token: token
      })
    })
  }

  /**
   * Sends a reset password email request
   * @param formValue email to reset password
   * @returns Observable
   */
  confirmEmail(formValue: any) {
    return this.http.post(`${environment.planningApi}auth/resetPassword/email`, formValue)
  }

  /**
   * Confirms a registered email address
   * @param email email to confirm
   * @returns Observable
   */
  confirmRegisteredEmail(email: string) {
    return this.http.post(`${environment.planningApi}auth/confirm/email`, { email })
  }
  /**
   * Resets the user password using the given token
   * @param formValue holds password, confirmation & token
   * @returns Observable
   */
  resetPassword(formValue: any) {
    return this.http.post(`${environment.planningApi}auth/resetPassword`, formValue)
  }

  /**
   * Gets a list of all workspaces assigned to a user
   * @returns Observable
   */
  public getWorkspaces() {
    return this.http.get(`${environment.planningApi}workspaces`).pipe(tap((workspaces: any) => (this.workspaces = workspaces.data)))
  }
  /**
   * Updates user details
   * @param formValue user updated information
   * @returns Observable
   */
  public updateUser(formValue: any): Observable<any> {
    return this.http.patch(`${environment.planningApi}auth/`, formValue)
  }

  /**
   * Updates user options metadata
   * @param option options metadata
   * @returns Observable
   */
  public updateUserOptions(option) {
    return this.http.patch(`${environment.planningApi}auth/`, { options: { display_tutorial: option } })
  }

  /**
   * Gets a list of all available plans
   * @returns Observable
   */
  public getPlans() {
    return this.http.get(`${environment.planningApi}plans`).pipe(tap((plans: any[]) => (this.plans = plans)))
  }

  /**
   * Gets a list of all available plans with it's prices objects
   * @returns Observable
   */
  public getPlansWithPrices() {
    return this.http.get<PlanPrice[]>(`${environment.planningApi}plans/prices`)
  }

  /**
   * Redirects the user to a stripe payment session
   * @param eventId event id
   * @param priceId price id
   * @param quantity quantity
   * @param success_url success url callback
   * @param cancel_url cancel url callback
   * @deprecated
   * @returns Observable
   */
  public postSession(eventId: number, priceId: string, quantity: number, success_url: string, cancel_url: string) {
    return this.http.post(`${environment.planningApi}sessions`, {
      event_id: eventId,
      price_id: priceId,
      quantity: quantity,
      success_url: success_url,
      cancel_url: cancel_url
    })
  }

  /**
   * Gets an organization info or if none exists returns an empty organization info object @see LegalInfo
   * @returns Observable
   */
  public getOrganizationInfo(workspace: Workspace = this.currentParentWorkspace): Observable<LegalInfo> {
    return this.http.get<LegalInfo>(`${environment.planningApi}workspaces/legal-info?workspace_id=${workspace.id}`).pipe(
      catchError(() => {
        return of({
          company_address: '',
          company_description: '',
          company_email: '',
          company_name: '',
          company_phone: '',
          company_type: null,
          country: '',
          workspace_id: workspace.id,
          industry_sector_id: null
        })
      })
    )
  }
  /**
   * Updates an organization profile
   * @param data LegalInfo @see LegalInfo
   * @returns Observable
   */
  public updateOrganization(data: any) {
    return this.http.patch(`${environment.planningApi}workspaces/legal-info`, data)
  }
  /**
   * Redirects the user to stripe to confirm his subscription
   * @param priceId product price id
   * @returns Observable
   */
  public createSubscription(priceId) {
    return this.http.post(`${environment.planningApi}sessions/subscription`, {
      workspace_id: this.currentParentWorkspace?.id ?? this.currentWorkspace?.id,
      price_id: priceId,
      return_url: `${environment.baseUrl}subscription/success`
    })
  }
  /**
   * Determines whether the user has any billing history
   * @deprecated
   * @returns Observable
   */
  public hasBillingHistory(): Observable<boolean> {
    const id = this.currentParentWorkspace?.id ?? this.currentWorkspace?.id
    if (!id) {
      return of(false)
    }
    return this.http.get(`${environment.planningApi}invoices/hasAny?workspace_id=${id}`).pipe(map((result: any) => result.hasAnyInvoices))
  }
  /**
   * Redirects the user to it's Stripe customer portal
   * @param return_url return url @deprecated
   * @returns Observable
   */
  public createCustomerPortal(return_url) {
    return this.http.post(`${environment.planningApi}sessions/customerPortal`, {
      workspace_id: this.currentParentWorkspace?.id ?? this.currentWorkspace?.id,
      return_url: `${environment.baseUrl + '/billing'}`
    })
  }
  /**
   * Gets the user token
   * @returns string
   */
  public getToken() {
    if (!this.user) {
      return null
    }
    return this.user['token']
  }

  /**
   * Deremintes whether the user is loggend in or not
   * @returns boolean
   */
  public get isLoggedIn() {
    return this.user != null
  }

  /**
   * Removes currently stored user
   */
  public clearUser() {
    this.user = null
  }

  /**
   * Gets the current user role
   * @returns string
   */
  get role(): 'Admin' | 'Main Entity' | 'Sub Entity' {
    if (!this.user && !this.currentWorkspace) {
      return null
    }
    if (this.user?.is_admin) {
      return 'Admin'
    }
    return this.currentWorkspace?.parent && this.currentWorkspace.parent_workspace?.id ? 'Sub Entity' : 'Main Entity'
  }
  /**
   * Gets the current user plan
   * @returns string | null
   */
  get plan(): 'Basic' | 'Pro' | '' | null {
    if (!this.user && !this.currentWorkspace) {
      return null
    }
    const planId = this.currentWorkspace?.parent_workspace ? this.currentWorkspace?.parent_workspace.plan_id : this.currentWorkspace?.plan_id

    switch (planId) {
      case 2:
        return 'Pro'
      case 1:
        return 'Basic'
      default:
        return ''
    }
  }

  /**
   * Signs up the user to the planning system
   * @param form user registration details
   * @returns Observable
   */
  public signup(form: any): Observable<any> {
    return this.http.post(`${environment.planningApi}auth/register`, { ...form })
  }

  /**
   * Checks if an email is already registered with MICEtribe
   * @param email email address to check
   * @returns Observable
   */
  public checkIfEmailRegistered(email: string): Observable<any> {
    return this.http.get(`${environment.planningApi}auth/canLogin?email=${email}`)
  }

  /**
   * Confirms an email address by a token sent to it's inbox
   * @param token string
   * @returns Observable
   */
  public validateEmailConfirmation(token: string) {
    return this.http.get(`${environment.planningApi}auth/confirm?token=${token}`)
  }

  /**
   * Get a list of available Industry Sectors
   * @returns Observable
   */
  getIndustrySectors(): Observable<any> {
    return this.http.get(`${environment.industrySector}`)
  }

  /**
   * Confirm whether all Facebook data has been deleted
   * @param userId
   * @returns Observable
   */
  public deleteFacebookDataConfirmation(userId: number): Observable<any> {
    return this.http.get(`${environment.planningApi}auth/login/facebook/delete/confirm?id=${userId}`)
  }

  /**
   * @public
   * @param {IRunningJob[]} jobs - The new running jobs.
   */
  updateRunningJobs(jobs: IRunningJob[]) {
    this.runningJobsSource.next(jobs)
  }

}

