import { AuthService } from '@auth/services/auth.service'
import { environment } from '@environments/environment'
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs'
import { Injectable, OnDestroy } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import {
  ServerRepresentedEvent,
  ServerRepresentedEventTiming,
  ServerRepresentedEventRegistrationsCount,
  ClientRepresentedEvent,
  ServerRepresentedSingleEventRegistrationsCount
} from '@events/models/event.model'
import { Zone } from '../models/zone.model'
import { map, switchMap, tap, takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { Persona } from '@personas/interfaces/persona.interface'
import { ServerRepresentedEventsCrewMembers } from '@events/models/crew-member.interface'
import { InvitationInfo } from '@app/modules/invite/models/invitation-info.interface'
import { DashboardResponse } from '../models/dashboard.model'

/**
 * Handles all event related API calls
 */
@Injectable({
  providedIn: 'root'
})
export class EventService implements OnDestroy {
  /** Subject that stops all hot observables */
  private _destroy$ = new Subject()
  /**
   * Determines if we are currently retrieving a list of events
   */
  public retrieving$: Subject<boolean> = new Subject<boolean>()

  /**
   * Determines the event status filter
   */
  public filter$: Subject<number> = new Subject()
  /** On destroy implementation */
  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  /**
   * Headers to be sent with the event requests
   */
  Headers = {
    user_token: this.auth.getToken()
  }

  /**
   * Creates the service and injects it's dependencies
   * @param http HttpClient
   * @param auth AuthService
   */
  constructor(private http: HttpClient, public auth: AuthService) { }

  fetchFinancialDashboardData(eventId: number): Observable<DashboardResponse> {
    return this.http.get<DashboardResponse>(`${environment.planningApi}events/${eventId}/financial_dashboard`)
  }

  updateCommissionValue(eventId: number, newCommission: number): Observable<ServerRepresentedEvent> {
    return this.http.put<ServerRepresentedEvent>(`${environment.planningApi}events/${eventId}/update_commission`, { commission: newCommission })
  }

  updateExtraCommissionValue(eventId: number, newExtraCommission: number): Observable<ServerRepresentedEvent> {
    return this.http.put<ServerRepresentedEvent>(`${environment.planningApi}events/${eventId}/update_extra_commission_amount`, { extra_commission_amount: newExtraCommission })
  }

  /**
   * Gets events based on the latest output of the term$ and page$ observable and the archived flag
   * Emites retrieving based on whether the observable is fired
   * @param term$ Observable
   * @param page$ Obsrvable
   * @param archived boolean
   * @returns Observable
   */
  search(term$: Observable<string>, page$: Observable<number>, archived: boolean = false) {
    return combineLatest([term$, this.filter$, page$]).pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(([_term, _filter, _page]) => {
        this.retrieving$.next(true)
        return this.getEvents(_page, _term, _filter, archived)
      })
    )
  }

  /**
   * Gets a list of events paginated
   * If there're events retrieves the counts via an api call
   * Then retrieves the crew members assigned to the event list
   * @param page number
   * @param keywords string
   * @param _filter number
   * @param archived boolean
   * @param perPage number
   * @returns Observable
   */
  getEvents(
    page: number | null = null,
    keywords: string | null = null,
    _filter: number,
    archived: boolean = false,
    perPage: number | null = null
  ): Observable<{ data: ServerRepresentedEvent[] }> {
    const requestedPage = !page ? '' : `&page=${page.toString()}`
    const requestedPerPage = !perPage ? `&perPage=5` : `&perPage=${perPage.toString()}`
    const requestedKeywords = !keywords ? '' : `&keyword=${keywords}`
    const requestedFilter = !_filter ? '' : `&status=${_filter}`
    const requestedArchive = !archived ? '' : '&is_archived=1'
    return this.http
      .get<{ data: ServerRepresentedEvent[] }>(
        `${environment.planningApi}events?workspace_id=${this.auth.currentWorkspace.id + requestedPage + requestedKeywords + requestedFilter + requestedArchive + requestedPerPage}`
      )
      .pipe(
        takeUntil(this._destroy$),
        tap(() => this.retrieving$.next(false))
      )
  }

  getBulkAssignees(events: ServerRepresentedEvent[]) {
    return this.http
      .post<ServerRepresentedEventsCrewMembers>(
        `${environment.crewApi}users/event/bulk-assignees`,
        {
          event_ids: events.map((o) => o.id)
        },
        {
          headers: new HttpHeaders({
            appId: '1'
          })
        }
      )
      .pipe(
        map((crewMembers) => {
          if (!crewMembers) {
            return events.map((event) => {
              event.crew = []
              return event
            })
          }
          events.forEach((event) => (event.crew = crewMembers[event.id] || []))
          return events
        })
      )
  }

  /**
   * Gets the registration count for the events list provided
   * @param events
   * @returns
   */
  getEventCounts(events: ServerRepresentedEvent[]): Observable<ServerRepresentedEvent[]> {
    return this.http
      .post<ServerRepresentedEventRegistrationsCount>(`${environment.registrationsApi}count/${this.auth.currentWorkspace.slug}`, {
        events: events.map((o) => o.slug)
      })
      .pipe(
        map((registrationsCount: ServerRepresentedEventRegistrationsCount): ServerRepresentedEvent[] => {
          if (!registrationsCount) {
            return events
          }
          Object.keys(registrationsCount).forEach((key: string) => {
            const event = events.find((o) => o.slug === key)
            if (event) {
              event.registration_count = registrationsCount[key]?.total || 0
              event.registration_count_display = !!registrationsCount[key]?.total
                ? (registrationsCount[key]?.attended || 0).toString() + '/' + (registrationsCount[key]?.total || 0).toString()
                : ''
            }
          })
          return events
        })
      )
  }

  /**
   * Gets a list of events except the events matching a certain status
   * @param exceptStatus number
   * @param keywords string
   * @returns Observable
   */
  getEventsExcept(exceptStatus: number, keywords: string = ''): Observable<ServerRepresentedEvent[]> {
    const requestedKeywords = !keywords ? '' : `&keyword=${keywords}`
    return this.http.get<any>(`${environment.planningApi}events?except=${exceptStatus.toString()}&workspace_id=${this.auth.currentWorkspace.id}${requestedKeywords}`)
  }

  /**
   * Gets a list of event forms, sets the registration fees as well
   * @param eventId number
   * @returns Observable
   */
  getEventForms(eventId: number): Observable<Persona[]> {
    return this.http.get<any>(`${environment.planningApi}forms?event_id=${eventId}`).pipe(
      map((res) => {
        if (!res.length) return
        res.forEach((form) => {
          const { options } = form
          if (options && options.reminders && !Array.isArray(options.reminders.schedules)) {
            const { schedules } = options.reminders
            options.reminders.schedules = Object.values(schedules)
          }
        })
        return res
      }),
      map((forms: Persona[]) =>
        forms
          .map((persona) => {
            const registration_amount: any = persona.registration_amount
            return {
              ...persona,
              registration_amount: registration_amount ? parseInt(registration_amount, 10) : null
            }
          })
          .sort((a, b) => (a.weight < b.weight ? -1 : 1))
      ),
      takeUntil(this._destroy$)
    )
  }
  /**
   * Updates an event and re-arranges it's zones
   * @param eventId number
   * @param event Partial<ServerRepresentedEvent>
   * @returns Observable
   */
  updateEvent(eventId, event: Partial<ServerRepresentedEvent>): Observable<ServerRepresentedEvent> {
    return this.http
      .patch<{ event: ServerRepresentedEvent; checkins: any[] }>(`${environment.planningApi}events`, { ...event, id: eventId })
      .pipe(takeUntil(this._destroy$))
      .pipe(
        map((result) => {
          const resultEvent = result.event
          const mainZone: Zone = result.checkins.find((o) => o.slug === 'main')
          resultEvent['zones'] = {
            main: mainZone,
            virtual: mainZone.subcheckins.find((o) => o.slug === 'global_virtual'),
            physical: mainZone.subcheckins.find((o) => o.slug === 'global')
          }
          return resultEvent
        })
      )
  }

  /**
   * Creates an event and re-arranges it's zones
   * @param event Partial<ServerRepresentedEvent>
   * @returns Observable
   */
  createEvent(event: Partial<ServerRepresentedEvent>): Observable<ServerRepresentedEvent> {
    return this.http
      .post<{ event: ServerRepresentedEvent; checkins: any[] }>(`${environment.planningApi}events`, event)
      .pipe(takeUntil(this._destroy$))
      .pipe(
        map((result) => {
          const resultEvent = result.event
          resultEvent['zones'] = {
            main: result.checkins.find((o) => o.slug === 'main'),
            virtual: result.checkins.find((o) => o.slug === 'global_virtual'),
            physical: result.checkins.find((o) => o.slug === 'global')
          }
          return resultEvent
        })
      )
  }

  /**
   * Begins an event export request
   * @param eventSlug string
   * @returns Observable
   */
  export(eventSlug) {
    return this.http.post(`${environment.exportsApi}export/xlsx/${this.auth.currentWorkspace.slug}/${eventSlug}/sendmail`, {}).pipe(takeUntil(this._destroy$))
  }

  /**
   * Checks the progress of the current export request
   * @param eventSlug string
   * @returns Observable
   */
  checkExportProgress(eventSlug) {
    return this.http.post(`${environment.exportsApi}export/xlsx/${this.auth.currentWorkspace.slug}/${eventSlug}/progress`, {}).pipe(takeUntil(this._destroy$))
  }

  /**
   * Gets an event, with it's event counts
   * @param eventId number
   * @returns Observable
   */
  getEvent(eventId: number): Observable<ServerRepresentedEvent> {
    return this.http
      .get<ServerRepresentedEvent>(`${environment.planningApi}events/find?event_id=${eventId}`)

      .pipe(
        switchMap((event: ServerRepresentedEvent) => {
          return this.http
            .get<ServerRepresentedSingleEventRegistrationsCount>(`${environment.registrationsApi}count/${this.auth.currentWorkspace.slug}/${event.slug}`)

            .pipe(
              map((registrationsCount: ServerRepresentedSingleEventRegistrationsCount): ServerRepresentedEvent => {
                event.registration_count = !!registrationsCount?.totalCount ? registrationsCount.totalCount : 0
                return event
              }),
              takeUntil(this._destroy$)
            )
        })
      )
  }

  /**
   * Gets all event info
   * @param workspaceSlug string
   * @param eventId number
   * @returns Observable
   */
  getEventInfo(eventId: number): Observable<ServerRepresentedEvent> {
    return this.http.get<ServerRepresentedEvent>(`${environment.planningApi}events/info?eventId=${eventId}`).pipe(takeUntil(this._destroy$))
  }

  /**
   * Gets an event for the Venue Capacity Dashboard
   * @param eventId number
   * @returns Observable
   */
  getVenueCapacityEvent(eventId: number): Observable<ServerRepresentedEvent> {
    return this.http.get<ServerRepresentedEvent>(`${environment.planningApi}events/find?event_id=${eventId}`).pipe(takeUntil(this._destroy$))
  }

  /**
   * Create event timings
   * @param eventId number
   * @param eventTiming ServerRepresentedEventTiming[]
   * @returns Observable
   */
  createEventTiming(eventId: number, eventTiming: ServerRepresentedEventTiming[]): Observable<ServerRepresentedEventTiming[]> {
    return this.http
      .post<ServerRepresentedEventTiming[]>(`${environment.planningApi}eventTimings`, {
        event_id: eventId,
        event_timings: eventTiming
      })
      .pipe(takeUntil(this._destroy$))
  }
  /**
   * Creates event zones and re-arranges the vent zones
   * @param event ClientRepresentedEvent
   * @param zones { physical: Zone; virtual: Zone }
   * @returns Observable
   */
  createEventZones(event: ClientRepresentedEvent, zones: { physical: Zone; virtual: Zone }): Observable<Zone[]> {
    if (!zones.physical.subcheckins.filter((o) => !o.id).length && !zones.virtual.subcheckins.filter((o) => !o.id).length) {
      return of([])
    }
    return this.http.post<Zone[]>(`${environment.planningApi}checkin`, {
      event_id: event.id,
      checkins: [
        ...zones.physical.subcheckins.filter((o) => !o.id).map((zone) => this.createZoneObject(zone, zones.physical)),
        // Virtual subzones
        ...zones.virtual.subcheckins.filter((o) => !o.id).map((zone) => this.createZoneObject(zone, zones.virtual))
      ]
    })
  }
  /**
   * Parses a server represented zone into it's client counterpart
   * @param zone Zone
   * @param parent Zone
   * @returns Zone
   */
  private createZoneObject(zone: Zone, parent: Zone) {
    const output = {
      ...zone,
      parent: parent.id,
      is_virtual: !!parent.is_virtual,
      max_capacity: !!zone.max_capacity ? zone.max_capacity : 0
    }
    if (output.id) {
      delete output.slug
    }
    return output
  }
  /**
   * Update event zones then re-rranges event zones then pushes them all to a forkJoin observable calling them in parallel
   * @param event ClientRepresentedEvent
   * @param zones { physical: Zone; virtual: Zone }
   * @returns Observable
   */
  updateEventZones(event: ClientRepresentedEvent, zones: { physical: Zone; virtual: Zone }) {
    const requests = []
    const physical = {
      event_id: event.id,
      ...zones.physical,
      max_capacity: !!zones.physical.max_capacity ? zones.physical.max_capacity : 0
    }
    const virtual = {
      event_id: event.id,
      ...zones.virtual,
      max_capacity: !!zones.virtual.max_capacity ? zones.virtual.max_capacity : 0
    }
    requests.push(
      ...zones.physical.subcheckins
        .filter((o) => !!o.id)
        .map((zone) => {
          return this.http.patch<Zone[]>(`${environment.planningApi}checkin`, this.createZoneObject(zone, zones.physical))
        })
    )
    requests.push(
      ...zones.virtual.subcheckins
        .filter((o) => !!o.id)
        .map((zone) => {
          return this.http.patch<Zone[]>(`${environment.planningApi}checkin`, this.createZoneObject(zone, zones.virtual))
        })
    )

    requests.push(this.http.patch<Zone[]>(`${environment.planningApi}checkin`, physical))
    requests.push(this.http.patch<Zone[]>(`${environment.planningApi}checkin`, virtual))
    return forkJoin(requests)
  }
  /**
   * Gets the event timings by an event id
   * @param eventId number
   * @returns Observable
   */
  getEventTiming(eventId: number): Observable<ServerRepresentedEventTiming[]> {
    return this.http.get<ServerRepresentedEventTiming[]>(`${environment.planningApi}eventTimings?event_id=${eventId}`)
  }
  /**
   * Gets the event zones by an event id
   * @param eventId number
   * @returns Observable
   */
  getEventZones(eventId: number): Observable<Zone[]> {
    return this.http.get<Zone[]>(`${environment.planningApi}checkin?event_id=${eventId}`).pipe(map((zone) => this.zoneMapper(zone)))
  }
  /**
   * Maps zones recursivle via forms, form_ids, subcheckins properties of a zone
   * @param zones any[]
   * @returns Mapped Zones
   */
  zoneMapper(zones: any[]) {
    return zones.map((o) => {
      o.forms = o.hasOwnProperty('forms') ? o.forms.map((f) => f?.id || f) : []
      o.form_ids = o.hasOwnProperty('forms') ? o.forms.map((f) => f?.id || f) : []
      o.subcheckins = o.hasOwnProperty('subcheckins') ? this.zoneMapper(o.subcheckins) : []

      return o
    })
  }

  /**
   * Updates the event timings associated with an event
   * @param eventId number
   * @param eventTiming ServerRepresentedEventTiming[]
   * @returns Observable
   */
  updateEventTiming(eventId: number, eventTiming: ServerRepresentedEventTiming[]): Observable<ServerRepresentedEventTiming[]> {
    return this.http
      .put<ServerRepresentedEventTiming[]>(`${environment.planningApi}eventTimings`, {
        event_id: eventId,
        event_timings: eventTiming
      })
      .pipe(takeUntil(this._destroy$))
  }

  /**
   * Gets the timezone information for a latitude and longitude literal
   * @param position LatLngLiteral
   * @returns Observble
   */
  getTimezone(position) {
    return this.http
      .get(`https://maps.googleapis.com/maps/api/timezone/json?location=${position.lat},${position.lng}&timestamp=${Date.now() / 1000}&key=${environment.publicGoogleMapsAPIKey}`)
      .pipe(takeUntil(this._destroy$))
  }

  /**
   * Archives an event
   * @param body archive event value
   * @returns Observable
   */
  archiveEvent(body: { id: number; is_archived: boolean }) {
    return this.http.patch(`${environment.planningApi}events`, body).pipe(takeUntil(this._destroy$))
  }
  /**
   * Updates event capacity
   * @param eventId number
   * @param num number
   * @param zone Zone
   * @returns Observable
   */
  updateCapacity(eventId, num, zone) {
    return this.http.patch(`${environment.planningApi}checkin`, {
      event_id: eventId,
      id: zone.id,
      max_capacity: num
    })
  }

  /**
   * Gets the zones by daily counts
   * @param workspaceSlug string
   * @param eventSlug string
   * @param zoneSlug string
   * @param startDate string
   * @param endDate string
   * @returns Observable
   */
  getDailyZOnes(workspaceSlug, eventSlug, zoneSlug, startDate, endDate) {
    return this.http.get(`${environment.planningDashboard}visitors/${workspaceSlug}/${eventSlug}/${zoneSlug}?start=${startDate}&end=${endDate}`)
  }

  /**
   * Gets the checkins for an event by it's id
   * @param eventId number
   * @returns Observable
   */
  getCheckins(eventId) {
    return this.http.get(`${environment.planningApi}checkin?event_id=${eventId}`)
  }

  /**
   * Gets the current counts for an event attendees by zones
   * @param workspace string
   * @param event string
   * @param zone string
   * @param identifier string
   * @returns Observable
   */
  pollCurrentAttendees(workspace: string, event: string, zone: string, identifier = '') {
    return this.http.get(`${environment.baseContactlessApi}v3/dashboards/check-count/${workspace}/${event}/${zone}`).pipe(
      takeUntil(this._destroy$),
      map((result: { checkinCount: number }) => {
        return {
          count: result.checkinCount,
          identifier: identifier
        }
      })
    )
  }
  /**
   * Deletes zones by it's ids
   * @param zoneIds number[]
   * @returns Observable
   */
  deleteZones(zoneIds: number[]) {
    return this.http.delete(`${environment.planningApi}checkin`, {
      params: {
        'ids[]': zoneIds.map((o) => o.toString(10))
      }
    })
  }

  /**
   *
   * @param eventId
   * @returns Observable
   */
  cloneEvent(eventId): Observable<{ event: ServerRepresentedEvent; checkins: [] }> {
    return this.http.post<{ event: ServerRepresentedEvent; checkins: [] }>(`${environment.planningApi}events/clone`, {
      id: eventId
    })
  }

  getOptOutStatus(workspace: string, invitationInfo: InvitationInfo, ids: string[]): Observable<{ notOptOut: number; opt_out: number }> {
    let { fromEventSlug, fromFormSlug } = invitationInfo
    return this.http.post<{ notOptOut: number; opt_out: number }>(`${environment.baseContactlessApi}v3/query/count/${workspace}/${fromEventSlug}/${fromFormSlug}/opt_out`, { ids:ids, action: invitationInfo.action })
  }

  getEventById(eventId: number): Observable<Partial<ServerRepresentedEvent>> {
    return this.http.get<Partial<ServerRepresentedEvent>>(`${environment.planningApi}events/find?event_id=${eventId}`)
  }

  /**
   * getting live and upcoming workspace paid events
   * @param workspaceId
   */
  getWorkspacePaidEvents(workspace_id: string) {
    return this.http
      .get<{ data: Partial<ServerRepresentedEvent>[] }>(`${environment.planningApi}/events/paid`, {
        params: { workspace_id }
      })
      .pipe(
        map((response: { message: string; data: Partial<ServerRepresentedEvent>[] }) => {
          return response.data
        })
      )
  }

  /**
   * Get the registration count for an event by form
   * @param eventSlug string
   * @returns Observable<{ form: string; count: number }[]>
   */
  getRegistrationCount(eventSlug: string): Observable<{ form: string; count: number }[]>{
    return this.http.get(`${environment.registrationsApi}process/event/${this.auth.currentWorkspace.slug}/${eventSlug}`).pipe(
      map((response: any) => {
        return response.forms.map((form: any) => {
          return {
            form: form.slug,
            count: form.registrations_count
          };
        });
      })
    );
  }
}