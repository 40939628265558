<div class="d-flex info align-items-center justify-content-center">
  <img [src]="workspaceLogo" class="logo" *ngIf="workspaceLogo" />
  <div class="details" [ngClass]="{ 'no-logo': !workspaceLogo }">
    <div class="welcome-msg">Hello!</div>
    <div class="mt-1 d-flex align-items-center text-left workspace-name">
      {{ workspaceName }}
    </div>
    <div class="bottom-text text-left">is inviting you to attend</div>
  </div>
</div>
<div class="msg-view">
  <div class="top-icon">
    <svg width="41" height="26" viewBox="0 0 41 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0L41 26H7L0 0Z" fill="#FFD333" />
    </svg>
  </div>
  <div class="msg-container">
    <ngx-editor
      [border]="false"
      [editorPadding]="false"
      [blackPlaceholder]="true"
      [containerHeight]="50"
      [placeholder]="'Type your invitation message'"
      [whiteBackground]="false"
      [invitationInfo]="invitationInfo"
      [height]="100"
    >
    </ngx-editor>
  </div>
</div>
<div class="event-info text-center justify-content-center">
  <img [src]="event.cover_page" class="event-cover" *ngIf="event.cover_page" />
  <img [src]="event.logo" class="event-logo" [ngStyle]="{ 'margin-top': !event.cover_page ? '60px' : 'auto' }" *ngIf="event.logo" />
  <div class="event-title" [ngStyle]="{ 'margin-top': !event.logo ? '30px' : '-12px' }">
    {{ event.name }}
  </div>
  <button status="primary">Click here to continue registration</button>
</div>
<div class="event-dates">
  <div class="date-header">
    <img src="https://res.cloudinary.com/contactless/image/upload/v1618317771/uploaded_public_files/swq2m3hzq3lokmctrkjb.svg" alt="timing icon" />
  </div>
  <div class="dates">
    <div class="title">{{ getDisplayDate(event.public_start_date, event.public_end_date) }}</div>
    <div class="days-details" *ngFor="let date of eventTimings">
      <span class="day">{{ getDisplayDay(date.day) }}</span>
      <span class="month">{{ getDisplayMonth(date.day) }}</span>
      <span class="timings">{{ date.start_datetime }} - {{ date.end_datetime }}</span>
      <span class="day-info" *ngIf="date.info">— {{ date.info }}</span>
    </div>
    <div class="add-to-calendar">
      <span>Add to Calendar</span>
    </div>
  </div>
</div>
<div class="map-box">
  <img [src]="event.location_details.map_direct_link.large" alt="">
  <!-- <ngx-mapbox-gl [event]="event" [addPadding]="false" [height]="294" [zoom]="10"></ngx-mapbox-gl> -->
</div>

<div *ngIf="hasSocialMedia" class="event-socials text-center d-flex justify-content-center align-items-center">
  <span *ngIf="event.social_media.facebookAccount"><nb-icon icon="facebook"></nb-icon></span>
  <span *ngIf="event.social_media.messenger_account"><nb-icon icon="facebook-messenger" pack="brands"></nb-icon></span>
  <span *ngIf="event.social_media.instagramAccount"><nb-icon icon="instagram" pack="brands"></nb-icon></span>
  <span *ngIf="event.social_media.snapchat_account"><nb-icon icon="snapchat" pack="brands"></nb-icon></span>
  <span *ngIf="event.social_media.linkedin_account"><nb-icon icon="linkedin"></nb-icon></span>
  <span *ngIf="event.social_media.twitter_account"><img height="15" width="15" src="https://library.micetribe.com/icons/new-twitter-logo(x).svg" alt="X" /></span>
  <span *ngIf="event.social_media.youtube_account"><nb-icon icon="youtube" pack="brands"></nb-icon></span>
  <span *ngIf="event.social_media.website"><nb-icon icon="globe-europe" pack="font-awesome"></nb-icon></span>
</div>
<p class="text-center template-footer">Opt-out from future event by {{ event.name }}</p>
