<nb-card [nbSpinner]="processing">
  <nb-card-body class="users-modal">
    <div class="container-fluid scroll">
      <div class="row m-0">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="users-title">Workspace Users</div>
        </div>
        <div class="col-12">
          <form *ngIf="form" [formGroup]="form" (ngSubmit)="save()">
            <div class="container-fluid" formArrayName="users">
              <div class="row user-row" *ngFor="let user of users.controls; let i = index">
                <div class="col-7 input-field" [formGroupName]="i">
                  <nb-form-field>
                    <mat-icon nbPrefix class="is-owner"> person</mat-icon>
                    <input type="text" nbInput fullWidth shape="rectangle" placeholder="Email" formControlName="email" />
                  </nb-form-field>
                </div>
                <div class="col-5 actions d-flex flex-row justify-content-between align-items-center" [formGroupName]="i">
                  <ng-container *ngIf="!user.value.is_owner || auth.user.is_admin">
                    <nb-select formControlName="role" fullWidth [disabled]="!canAddUser(user) && !canChangeUser(user) || user.value.is_owner && user.value.role == 'admin'" status="basic">
                      <nb-option *ngFor="let role of availableRoles" [value]="role" [disabled]="!rolesList().includes(role)">{{
                        user.value.is_owner && role === 'admin' ? 'owner' : role
                      }}</nb-option>
                    </nb-select>
                  </ng-container>
                  <ng-container *ngIf="user.value.is_owner && !auth.user.is_admin">
                    <nb-select fullWidth disabled placeholder="Owner"> </nb-select>
                  </ng-container>
                  <div class="w-100">
                    <button
                      *ngIf="!user.value.is_owner || auth.user.is_admin"
                      class="action"
                      nbButton
                      size="small"
                      status="basic"
                      [disabled]="!canDeleteUser(user)"
                      (click)="open(dialog, $event, user, i)"
                      role="button"
                      type="button"
                    >
                      <mat-icon>delete</mat-icon>
                      Delete
                    </button>
                  </div>
                </div>
                <span class="email-error" *ngIf="user.controls.email.touched && user.controls.email.errors">This doesn't seem to be an email format</span>
              </div>
              <div *ngIf="canAddUsers()" class="row">
                <div class="col-5">
                  <button nbButton fullWidth (click)="addUser()" status="basic" role="button" type="button">+ Add user</button>
                </div>
              </div>
              <div class="row error-area">
                <div class="col-12 d-flex justify-content-center align-items-center">
                  <div *ngIf="form?.errors?.duplicateEmail" class="text-danger text">{{ form.errors.duplicateEmail }} is already used</div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 d-flex justify-content-center align-items-center">
                  <button nbButton class="save-button" role="button" type="submit" status="primary">Save</button>
                  <button nbButton class="cancel-button" role="button" (click)="ref.close()" status="basic">Cancel</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-body class="users-modal">
      <div class="container-fluid">
        <div class="row">
          <div class="confirm-text">Would you like to remove {{ specificUser }} from this workspace?</div>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-center align-items-center">
            <button nbButton fullWidth class="close-button" role="button" status="primary" (click)="deleteUser(); ref.close()">Confirm</button>
            <button nbButton fullWidth class="close-button" role="button" (click)="ref.close()">Cancel</button>
          </div>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
